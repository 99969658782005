import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './keypoint.css'

const index = () => {
    return (
        <>
            <section className='keypoint-bg p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row className='px-md-5 px-0'>
                        <h4 class="roboto-regular fw-bold intro-h4 mb-4 text-center">WHY CHOOSE US?</h4>
                        <Col lg={4}>
                            {/* <Card style={{ width: '18rem' }}> */}
                            <Card className='card-bg p-4 text-center'>
                                <Card.Img variant="top" src="img/icon1.png" className='card-icon mx-auto' />
                                <Card.Body>
                                    <Card.Title className='roboto-bold'>
                                        <span className='num-font'>01.</span>&nbsp;
                                        Fully Functional App</Card.Title>
                                    <Card.Text>
                                        Experience a seamless and comprehensive user interface designed for all your needs.
                                    </Card.Text>
                                    <div class="mt-4"><a href="#" class="text-decoration-none blue-button font-weight-normal">DOWNLOAD <i class="bi bi-download"></i></a></div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} className='mt-lg-0 mt-3'>
                            {/* <Card style={{ width: '18rem' }}> */}
                            <Card className='card-bg p-4 text-center'>
                                <Card.Img variant="top" src="img/icon2.png" className='card-icon mx-auto' />
                                <Card.Body>
                                    <Card.Title className='roboto-bold'>
                                        <span className='num-font'>02.</span>&nbsp;
                                        Maximum Secure Data</Card.Title>
                                    <Card.Text>
                                        Enjoy peace of mind with top-tier encryption and robust security measures protecting your sensitive information. </Card.Text>
                                    <div class="mt-4"><a href="#" class="text-decoration-none blue-button font-weight-normal">DOWNLOAD <i class="bi bi-download"></i></a></div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} className='mt-lg-0 mt-3'>
                            {/* <Card style={{ width: '18rem' }}> */}
                            <Card className='card-bg p-4 text-center'>
                                <Card.Img variant="top" src="img/icon3.png" className='card-icon mx-auto' />
                                <Card.Body>
                                    <Card.Title className='roboto-bold'>
                                        <span className='num-font'>03.</span>&nbsp;
                                        Multilingual Support
                                    </Card.Title>
                                    <Card.Text>
                                        Access our user-friendly app in multiple languages, making it perfect for a global audience.</Card.Text>
                                    <div class="mt-4"><a href="#" class="text-decoration-none blue-button font-weight-normal">DOWNLOAD <i class="bi bi-download"></i></a></div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
