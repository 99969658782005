import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Logo'
import './header.css'

const Index = () => {
    return (
        <>
            {/* <Navbar fixed="top" collapseOnSelect expand="lg" className="bg-body-tertiary" > */}
            <Navbar fixed="top" collapseOnSelect expand="lg" className="bg-body-tertiary" >
                <Container>
                    <Navbar.Brand href="/"><Logo /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href="/" className='text-lg-start text-center'>HOME</Nav.Link>
                            <Nav.Link href="/about" className='text-lg-start text-center'>ABOUT</Nav.Link>
                            <Nav.Link href="/faq" className='text-lg-start text-center'>FAQ's</Nav.Link>
                            <Nav.Link href="/news" className='text-lg-start text-center'>NEWS</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="/contact" className='nav-button text-center'>CONTACT US</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Index
