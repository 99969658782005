import React from 'react'
import Header from '../../Common/Header'
import Banner from '../Home/Banner'
import Keypoints from '../Home/Keypoints'
import Intro from '../Home/Intro'
import Choose from '../Home/Choose'
import Screenshot from '../Home/Screenshot'
import Testimonial from '../Home/Testimonial'
import Footer from '../../Common/Footer'

const index = () => {
    return (
        <>
            <Header />
            <Banner />
            <Intro />
            <Keypoints />
            <Choose />
            <Screenshot />
            <Testimonial />
            <Footer />
        </>
    )
}

export default index
