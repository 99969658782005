import React from 'react'
import './logo.css'

const index = () => {
    return (
        <>
            <img src="img/logo.png" className='logo' />
        </>
    )
}

export default index
