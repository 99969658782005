import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './banner.css'

const index = () => {
    return (
        <>
            <section className='area'>
                <Container>
                    <Row className=''>
                        <Col>
                            <Row className='mt-3 p-5'>
                                <Col className='my-auto text-lg-start text-center'>
                                    <h4 className='roboto-regular fw-bold'>
                                        Go Aruba - One Happy App
                                    </h4>
                                    <h1 className='heading-h1 mt-3 mb-3'>
                                        We Welcome You To GO ARUBA
                                    </h1>
                                    <h6 className=''>
                                        Go Aruba is revolutionizing the way we do our daily tasks.
                                        With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep. You can also book an appointment at your favorite barber, nail salon, hair salon and much more.
                                    </h6>
                                    <div className='mt-5'>
                                        <a href='#' className='text-decoration-none nav-button font-weight-normal'>DOWNLOAD <i class="bi bi-download"></i></a>
                                    </div>

                                </Col>
                                <Col className='text-center'>
                                    <div className='pt-4'>
                                        <img src="img/banner.png" className='banner-img' />
                                    </div>
                                </Col>
                            </Row>

                            <div class="" >
                                <ul class="circles">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div >
                        </Col>
                    </Row >
                </Container >
            </section >
        </>
    )
}

export default index
