import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import './testimonial.css'

const index = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },

        ]
    };

    return (

        <>
            <section className='testimonial-bg p-md-5 p-3'>
                <Row>
                    <Col>
                        <h4 class="roboto-regular fw-bold intro-h4 mb-4 text-center">CLIENTS TESTIMONIAL</h4>
                        <h1 class="intro-h1 mt-3 mb-4 text-center">15k+ Customers Love Go Aruba</h1>
                        <h6 class="text-center px-md-5 mx-5 px-3">With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep. You can also book an appointment at your favorite barber, nail salon, hair salon and much more.</h6>
                        <div className=''>
                            <Slider {...settings}>
                                <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                        {/* <img class="card-img-top card-icon mx-auto" src="img/icon2.png" /> */}
                                        <p class="card-text">
                                            <i>
                                                “The App's seamless functionality has made managing my daily tasks incredibly easy.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            Sarah L.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                        {/* <img class="card-img-top card-icon mx-auto" src="img/icon2.png" /> */}
                                        <p class="card-text"><i>“I trust the Multiservice App completely thanks to its robust security features.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            David R.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                        {/* <img class="card-img-top card-icon mx-auto" src="img/icon2.png" /> */}
                                        <p class="card-text"><i>“With the Multiservice App, I can stay organized and efficient no matter where I am.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            Jessica K.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                        {/* <img class="card-img-top card-icon mx-auto" src="img/icon2.png" /> */}
                                        <p class="card-text"><i>“The multilingual support is fantastic; my global team uses it with ease.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            Emily T.
                                        </div>
                                    </div>
                                </div>
                                <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                        {/* <img class="card-img-top card-icon mx-auto" src="img/icon2.png" /> */}
                                        <p class="card-text"><i>“This app combines everything I need in one place, making my life so much simpler.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            Michael W.
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='p-5'>
                                    <div class=" p-4 text-center card">
                                    
                                        <p class="card-text"><i>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinarac dapibus leo. Cras cursus facere, numquam ultrica orci optio! Fames, ab alias.”</i>
                                        </p>
                                        <div class="roboto-bold card-title h5">
                                            Lorem Ipsum
                                        </div>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    )
}

export default index
