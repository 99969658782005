import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import './faq-intro.css'

const index = () => {
    return (
        <>
            <section className='p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row>

                        <Col xl={6}>
                            <Col className='my-auto'>
                                <h4 className='roboto-regular fw-bold intro-h4 mt-4'>

                                    ANY QUESTIONS
                                </h4>
                                <h1 className='intro-h1 mt-3 mb-4'>
                                    Frequently Asked Questions !!
                                </h1>
                                <h6 className='mt-3'>
                                    We aims to improve user experience by providing immediate answers, reduce repetitive inquiries to customer support or service teams, and enhance overall efficiency.
                                </h6>
                                <div className='mt-4'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0" className='my-3'>
                                            <Accordion.Header>Can I schedule services in advance?</Accordion.Header>
                                            <Accordion.Body>
                                                Yes, you can schedule services like ride-hailing and home services in advance by selecting the preferred date and time during the booking process.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className='my-3'>
                                            <Accordion.Header>How is my personal information protected?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                Your personal information is stored securely and used only for providing the services you request. We do not share your information with third parties without your consent.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" className='my-3'>
                                            <Accordion.Header>How do I download the Multiservice App?</Accordion.Header>
                                            <Accordion.Body>
                                                You can download the Multiservice App from the App Store for iOS devices or the Google Play Store for Android devices.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                            </Col>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={5}>
                            <div className=''>
                                <div className=''>
                                    <h4 className='roboto-regular fw-bold  mt-5'>Still Have A Questions?</h4>
                                    <h6 class="mt-3">For any queries reach out to us at support@goaruba.com or write to us through the below form. </h6>
                                    <Form className='mt-4'>
                                        <fieldset disabled>
                                            <Form.Group className="mb-3">
                                                <Form.Control id="disabledTextInput" type="text" placeholder="Your Name" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control id="disabledTextInput" type="email" placeholder="Your Email" required />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Control id="disabledTextInput" type="tel" placeholder="Your Number" required />
                                            </Form.Group>
                                            <Form.Control
                                                as="textarea"
                                                placeholder="Leave a comment here"
                                                style={{ height: '100px' }}
                                            />
                                            <div class="mt-4"><a href="#" class="text-decoration-none blue-button font-weight-normal">SUBMIT</a></div>
                                        </fieldset>
                                    </Form>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className='mt-5'>
                        <Col xl={6}>
                            <Col className='my-auto'>
                                <h4 className='roboto-regular fw-bold intro-h4 mt-4'>

                                    QUESTIONS/ANSWERS
                                </h4>
                                <h1 className='intro-h1 mt-3 mb-4'>
                                    What We do & Benefits !!
                                </h1>
                                <h6 className='mt-3'>
                                    Helping users find information quickly and reducing the need for direct support.
                                </h6>
                                <div className='mt-4'>
                                    <Accordion>
                                        <Accordion.Item eventKey="0" className='my-3'>
                                            <Accordion.Header>How can I contact customer support?</Accordion.Header>
                                            <Accordion.Body>
                                                You can contact our customer support team through the "Help" section in the app or by emailing support@goaruba.com
                                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                culpa qui officia deserunt mollit anim id est laborum.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1" className='my-3'>
                                            <Accordion.Header>What should I do if I have an issue with my order?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                If you encounter any issues with your order, please go to the "Order History" section, select the order in question, and choose the "Report Issue" option. Our support team will assist you.
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" className='my-3'>
                                            <Accordion.Header>What payment methods are accepted?</Accordion.Header>
                                            <Accordion.Body>
                                                The app accepts various payment methods, including credit/debit cards, digital wallets, and cash on delivery (where applicable).
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                            </Col>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={5}>
                            <div className=''>
                                <div className='mt-4'>
                                    <div className='intro-img-bg'>
                                        <div className=''>
                                            <img src="img/faq.jpg" className='intro-img' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
