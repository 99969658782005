import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './about-banner.css'

const index = () => {
    return (
        <>
            <section className='area-about'>
                <Container>
                    <Row className=''>
                        <Col>
                            <h1 className='heading-h1 text-center margin-banner-top'>
                                ABOUT US
                            </h1>
                            <div class="" >
                                <ul class="circles">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div >
                        </Col>
                    </Row >
                </Container >
            </section >
        </>
    )
}
export default index
