import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './about-intro.css';

const index = () => {
    return (
        <>
            <section className='p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row>
                        <Col xl={6}>
                            <div className='intro-img-bg'>
                                <div className=''>
                                    <img src="img/intro.jpg" className='intro-img' />
                                </div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <Col className='my-auto'>
                                <h4 className='roboto-regular fw-bold intro-h4 mt-4'>
                                    Go Aruba One Happy App<br />
                                </h4>
                                <h1 className='intro-h1 mt-3 mb-4'>
                                    We welcome you to GoAruba!
                                </h1>
                                <h6 className=''>
                                    With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep. You can also book an appointment at your favorite barber, nail salon, hair salon and much more.
                                </h6>
                                <h6 className='mt-3'>
                                    You can search for specific services like
                                </h6>
                                <div className=''>
                                    <Row>
                                        <Col md={6}>
                                            <ListGroup as="ul">
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Plumbers
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Mechanics
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Electricians
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                        <Col md={6}>
                                            <ListGroup as="ul">
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Home Cleaning
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Taxi's
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Car Wash
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>


                                </div>

                                <div className='mt-5'>
                                    <a href='#' className='text-decoration-none blue-button font-weight-normal'>DOWNLOAD <i class="bi bi-download"></i></a>
                                </div>

                            </Col>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        <Col xl={6}>
                            <Col className='p-xl-5 p-0'>
                                <h4 className='roboto-regular fw-bold  mt-5'>
                                    Our goal is to make your life and travels easier
                                </h4>

                                <h6 className='mt-3'>
                                    We know the struggles of not being able to have your favorite food delivered right where you want whenever you want, you need a fixer upper? Go to your Go Aruba app! You need a haircut? It’s only one click away!<br /><br />
                                    We offer a modern perspective of how to experience aruba. Nowadays technology has taken over and constantly evolving. Everywhere around the world we are able to enjoy the fruits of this advancement in technology, except for Aruba. Most of us still have to personally call a restaurant to make our reservations. Calling taxis to pick us up. Physically going to the supermarket to get our groceries. Leaving the comfort of our homes to be able to eat at our favorite restaurants because there are no delivery services. Now imagine having all of that under one roof.
                                </h6>


                                <div className='mt-5'>
                                    <a href='#' className='text-decoration-none blue-button font-weight-normal'>DOWNLOAD <i class="bi bi-download"></i></a>
                                </div>

                            </Col>
                        </Col>
                        <Col xl={6}>
                            <div className='intro-img-bg'>
                                <div className=''>
                                    <img src="img/intro.jpg" className='intro-img' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
