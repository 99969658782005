import React from 'react'
import Header from '../../Common/Header'
import ContactBanner from './ContactBanner'
import ContactIntro from './ContactIntro'
import Footer from '../../Common/Footer'


const index = () => {
    return (
        <>
            <Header />
            <ContactBanner />
            <ContactIntro />
            <Footer />
        </>
    )
}

export default index
