import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './choose.css'

const index = () => {
    return (
        <>
            <section className='px-md-5 px-3 '>
                <Container className='p-md-5 p-0'>
                    <Row className='p-md-5 p-3 m-0'>
                        <Row className='row choose-bg my-md-auto m-0'>
                            <Col xl={6} className='text-xl-start text-center'>
                                <div className=''>
                                    <img src="img/choose.jpg" className='choose-img' />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className='my-auto text-xl-start text-center'>
                                    <h4 class="roboto-regular fw-bold  mt-4 ">CHOOSE YOUR DEVICE PLATFORM</h4>
                                    <h1 class="intro-h1 mt-3 mb-4 ">Checkout Our App</h1>
                                    <h6 class="">Et optio praesent et! Eligendi ab, irure impedit rhoncus, doloremque! Pretium nobis nobis modi ullamco.</h6>
                                    <Row className='mt-4'>
                                        <Col>
                                            <img src="img/google-play.png" className='app-store' />
                                        </Col>
                                        <Col className='mt-md-0 mt-3'>
                                            <img src="img/apple-store.png" className='app-store' />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
