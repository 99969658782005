import React from 'react'
import Header from '../../Common/Header'
import AboutIntro from './AboutIntro'
import AboutBanner from './AboutBanner'
import Choose from '../Home/Choose'
import Footer from '../../Common/Footer'


const index = () => {
    return (
        <>
            <Header />
            <AboutBanner />
            <AboutIntro />
            <Choose />
            <Footer />
        </>
    )
}

export default index
