import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import './news-intro.css'

const index = () => {
    return (
        <>
            <section className='p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row>
                        <Col lg={4} className='mt-lg-0 mt-3'>
                            <div class="card p-3" >

                                <img src="img/faq.jpg" class="card-img-top mt-3 mb-4" alt="..." />
                                <p class="blog-info">
                                    By Jhonwooo
                                </p>
                                <h5 class="card-title text-start">Best Gadget Of The Year</h5>
                                <p class="blog-text text-start">Every year, technology enthusiasts eagerly anticipate the unveiling of the latest gadgets that promise to revolutionize our daily lives. 2024 has been no exception, bringing with it a myriad of innovative devices. </p>
                                <a href="" class="text-decoration-none orange blog-text">Read More</a>
                            </div>
                        </Col>
                        <Col lg={4} className='mt-lg-0 mt-3'>
                            {/* <div class="card p-3" >

                                <img src="img/faq.jpg" class="card-img-top mt-3 mb-4" alt="..." />
                                <p class="blog-info">
                                    By Jhonwooo
                                </p>
                                <h5 class="card-title text-start">AI Based Awareness</h5>
                                <p class="blog-text text-start">Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                    vulputate
                                    libero et
                                    velit interdum, ac aliquet odio mattis. </p>
                                <a href="" class="text-decoration-none orange blog-text">Read More</a>
                            </div> */}
                        </Col>
                        <Col lg={4} className='mt-lg-0 mt-3'>
                            {/* <div class="card p-3" >

                                <img src="img/faq.jpg" class="card-img-top mt-3 mb-4" alt="..." />
                                <p class="blog-info">
                                    By Jhonwooo
                                </p>
                                <h5 class="card-title text-start">AI Based Awareness</h5>
                                <p class="blog-text text-start">Rorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                                    vulputate
                                    libero et
                                    velit interdum, ac aliquet odio mattis. </p>
                                <a href="" class="text-decoration-none orange blog-text">Read More</a>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
