import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Footer from 'react-bootstrap/Footer';
import Logo from '../Logo';
import './footer.css'

const index = () => {
    return (
        <>
            <section className='text-center text-md-start bg-footer mt-5'>
                <section className='pt-4'>
                    <Container className='text-center text-lg-start mt-5'>
                        <Row className='mt-3'>
                            <Col md='12' lg='4' xl='3' className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>
                                    <Logo />

                                </h6>
                                <h6>
                                    Go aruba is revolutionizing the way we do our daily tasks. With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep.
                                </h6>
                            </Col>



                            <Col md='12' lg='2' xl='2' className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
                                <h6>
                                    <a href='/about' className='text-reset'>
                                        About
                                    </a>
                                </h6>
                                <h6>
                                    <a href='/faq' className='text-reset'>
                                        Faq's
                                    </a>
                                </h6>
                                <h6>
                                    <a href='news' className='text-reset'>
                                        News
                                    </a>
                                </h6>
                                <h6>
                                    <a href='contact' className='text-reset'>
                                        Contact
                                    </a>
                                </h6>
                            </Col>

                            <Col md='12' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                                <h6>
                                    <i class="bi bi-geo-alt"></i> 113th Street, Suite 721 New York
                                </h6>
                                <h6>
                                    <i class="bi bi-envelope"></i> info@example.com
                                </h6>
                                <h6>
                                    <i class="bi bi-telephone"></i> + 01 234 567 88
                                </h6>
                                <h6>
                                    <i class="bi bi-telephone"></i> + 01 234 567 89
                                </h6>
                            </Col>
                            <Col md='12' lg='2' xl='2' className='mx-auto mb-4'>
                                <h6 className='text-uppercase fw-bold mb-4'>SUPPORT</h6>
                                <h6>
                                    For any queries reach out to us by mailing us at support@goaruba.com.
                                </h6>

                            </Col>
                        </Row>
                    </Container>
                </section>

                <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    Copyright © 2024&nbsp;
                    <a className='text-reset fw-bold' href='#'>
                        Go Aruba.&nbsp;
                    </a>
                    All rights reserved.
                </div>
            </section>
        </>
    )
}

export default index
