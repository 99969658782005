import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Slider from "react-slick";
import './screenshot.css'

const index = () => {
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 2500,
        autoplaySpeed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplay: true,
    };
    return (
        <>
            <section className='p-md-5 p-3'>
                <Container>
                    <Row className='text-lg-start text-center'>
                        <Col lg={8} className='my-auto'>
                            <h4 className='roboto-regular fw-bold intro-h4 mt-4'>
                                CHECKOUT APP PAGE
                            </h4>
                            <h1 className='intro-h1 mt-3 mb-4'>
                                Our App Screenshots
                            </h1>
                            <h6 className=''>
                                With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep. You can also book an appointment at your favorite barber, nail salon, hair salon and much more.
                            </h6>
                            <h6 className='mt-3'>
                                You can search for specific services like
                            </h6>
                            <Row className='g-3 mt-4'>
                                <Col>
                                    <div className='p-3 screeshot-tab text-center'>
                                        <img class="card-img-top card-icon mx-auto" src="img/users.png" />
                                        <h5 className='roboto-bold mt-3 num-font'>
                                            10M+</h5>
                                        <h5 className='roboto-bold '>Active Users</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-3 screeshot-tab text-center mt-5'>
                                        <img class="card-img-top card-icon mx-auto" src="img/download.png" />
                                        <h5 className='roboto-bold mt-3 num-font'>
                                            25
                                            M+</h5>
                                        <h5 className='roboto-bold '>Downloads</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-3 screeshot-tab text-center '>
                                        <img class="card-img-top card-icon mx-auto" src="img/award.png" />
                                        <h5 className='roboto-bold mt-3 num-font'>
                                            30+</h5>
                                        <h5 className='roboto-bold '>Award Achieved</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='p-3 screeshot-tab text-center mt-5'>
                                        <img class="card-img-top card-icon mx-auto" src="img/rating.png" />
                                        <h5 className='roboto-bold mt-3 num-font'>
                                            800+</h5>
                                        <h5 className='roboto-bold '>Positive Rating</h5>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className='p-5'>
                            <Slider {...settings}>
                                <div>
                                    <img src="img/1.jpg" className='' />
                                </div>
                                <div>
                                    <img src="img/2.jpg" className='' />
                                </div>
                                <div>
                                    <img src="img/3.jpg" className='' />
                                </div>
                                <div>
                                    <img src="img/4.jpg" className='' />
                                </div>
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
