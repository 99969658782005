import React from 'react'
import Header from '../../Common/Header'
import FaqBanner from './FaqBanner'
import FaqIntro from './FaqIntro'
import Footer from '../../Common/Footer'

const index = () => {
    return (
        <>
            <Header />
            <FaqBanner />
            <FaqIntro />
            <Footer />
        </>
    )
}

export default index
