import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import './contact-intro.css'

const index = () => {
    return (
        <>
            <section className='p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row>

                        <Col xl={6}>
                            <Col className='my-auto'>
                                <h4 className='roboto-regular fw-bold intro-h4 mt-4'>
                                    GET IN TOUCH
                                </h4>
                                <h1 className='intro-h1 mt-3 mb-4'>
                                    Contact us & Reach !!
                                </h1>
                                <h6 className='mt-3'>
                                    For any queries reach out to us at support@goaruba.com or write to us through the below form.
                                </h6>
                                <Form className='mt-4'>
                                    <fieldset disabled>
                                        <Form.Group className="mb-3">
                                            <Form.Control id="disabledTextInput" type="text" placeholder="Your Name" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control id="disabledTextInput" type="email" placeholder="Your Email" required />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Control id="disabledTextInput" type="tel" placeholder="Your Number" required />
                                        </Form.Group>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Leave a comment here"
                                            style={{ height: '100px' }}
                                        />
                                        <div class="mt-4"><a href="#" class="text-decoration-none blue-button font-weight-normal">SUBMIT</a></div>
                                    </fieldset>
                                </Form>

                            </Col>
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={5} lg={6} className='mt-5'>
                            <div className='mt-5'>
                                <Row className='mt-5'>
                                    <Col xs={2}>
                                        <div className='contact-icon'>
                                            <i class="bi bi-geo-alt"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <h4 className='roboto-regular fw-bold intro-h4'>Location Address</h4>
                                        <h6>Los Angeles City Hall 132A Spring St, Los Angeles, Newyork, USA</h6>
                                    </Col>
                                </Row>
                                <Row className='mt-5'>
                                    <Col xs={2}>
                                        <div className='contact-icon'>
                                            <i class="bi bi-telephone"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <h4 className='roboto-regular fw-bold intro-h4'>Phone Number</h4>
                                        <h6>Tel : (033) 131-34-561 / +01 2599 12<br />
                                            Mob : (032) 169-34-862 (033)
                                        </h6>
                                    </Col>
                                </Row>
                                <Row className='mt-5'>
                                    <Col xs={2}>
                                        <div className='contact-icon'>
                                            <i class="bi bi-envelope-open"></i>
                                        </div>
                                    </Col>
                                    <Col>
                                        <h4 className='roboto-regular fw-bold intro-h4'>Email Address</h4>
                                        <h6>support@goaruba.com<br />
                                            contact@goaruba.com</h6>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                    <Row className='mt-5'>
                        <Col className='mt-5'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.6162538237713!2d-118.24522822448338!3d34.053713573156465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c64f3b01358f%3A0x5723556b26dfefac!2sLos%20Angeles%20City%20Hall!5e0!3m2!1sen!2sin!4v1721919792835!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
