import './App.css';
// import Header from './Components/Common/Header'
import Home from './Components/Pages/Home'
import About from './Components/Pages/About'
import News from './Components/Pages/News'
import Faq from './Components/Pages/Faq'
import Contact from './Components/Pages/Contact'
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      {/* <Home /> */}
      <>
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} ></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/about" element={<About />} ></Route>
            <Route path="/news" element={<News />} ></Route>
            <Route path="/faq" element={<Faq />} ></Route>
            <Route path="/contact" element={<Contact />} ></Route>
          </Routes>
        </BrowserRouter >
      </>
    </>
  );
}

export default App;
