import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './intro.css';

const index = () => {
    return (
        <>
            <section className='p-md-5 p-0'>
                <Container className='p-md-5 p-3'>
                    <Row>
                        <Col className='text-md-start text-center my-md-0 my-5' >
                            <div className='intro-img-bg'>
                                <div className=''>
                                    <img src="img/intro.jpg" className='intro-img' />
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} className='text-lg-start text-center'>
                            <Col className='my-auto'>
                                <h4 className='roboto-regular fw-bold intro-h4 mt-4'>
                                    INTRODUCTION TO US
                                </h4>
                                <h1 className='intro-h1 mt-3 mb-4'>
                                    Delivering Exceptional User Experiences
                                </h1>
                                <h6 className=''>
                                    With Go Aruba you are able to order food from your favorite restaurant, and have it delivered to your doorstep. You can also book an appointment at your favorite barber, nail salon, hair salon and much more.
                                </h6>
                                <h6 className='mt-3'>
                                    You can search for specific services like
                                </h6>
                                <div className='text-start'>
                                    <Row>
                                        <Col><ListGroup as="ul">
                                            <ListGroup.Item as="li" >
                                                <i class="bi bi-check"></i> &nbsp;Plumbers
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" >
                                                <i class="bi bi-check"></i> &nbsp;Mechanics
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" >
                                                <i class="bi bi-check"></i> &nbsp;Electricians
                                            </ListGroup.Item>
                                        </ListGroup>
                                        </Col>
                                        <Col>
                                            <ListGroup as="ul">
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Home Cleaning
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Taxi's
                                                </ListGroup.Item>
                                                <ListGroup.Item as="li" >
                                                    <i class="bi bi-check"></i> &nbsp;Car Wash
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Col>
                                    </Row>


                                </div>

                                <div className='mt-5'>
                                    <a href='#' className='text-decoration-none blue-button font-weight-normal'>DOWNLOAD <i class="bi bi-download"></i></a>
                                </div>

                            </Col>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default index
