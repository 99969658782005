import React from 'react'
import Header from '../../Common/Header'
import NewsBanner from './NewsBanner'
import NewsIntro from './NewsIntro'
import Footer from '../../Common/Footer'

const index = () => {
    return (
        <>
            <Header />
            <NewsBanner />
            <NewsIntro />
            <Footer />
        </>
    )
}

export default index
